import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker/generatePicker/interface';
import { Locale } from 'antd/es/locale';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { Moment } from 'moment';
import { FC, useRef, useState } from 'react';

const styles: InlineStylesModel = {
  form: {
    position: 'relative'
  },
  borderAnimation: {
    transition: 'all 0.3s ease'
  },
  input: {
    width: '100%',
    minWidth: 125
  },
  labelUnfocused: {
    position: 'absolute',
    color: 'rgba(0,0,0,0.50)',
    top: 5,
    left: 10,
    zIndex: 9999,
    transition: 'all 0.3s ease',
    visibility: 'hidden'
  },
  labelFocused: {
    backgroundColor: 'white',
    position: 'absolute',
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: 9999,
    top: -15,
    left: 8,
    transition: 'all 0.3s ease'
  }
};

const { RangePicker } = DatePicker;

interface Props extends RangePickerProps<Locale> {
  rangeValues: Moment[];
  label: string;
  width?: string | number;
}

export const FancyDateRangePicker: FC<Props> = ({ rangeValues, label, width }) => {
  const [isLabelTop, _setIsLabelTop] = useState(false);

  const inputRef = useRef(null);

  const inputWidth = width ? { ...styles.input, ...{ width } } : styles.input;

  const handleFocus = (): void => {
    _setIsLabelTop(true);
  };

  const handleBlur = (): void => {
    if (!rangeValues.length) {
      _setIsLabelTop(false);
    }
  };

  return (
    <div style={{ ...styles.form, ...styles.borderAnimation }}>
      <RangePicker ref={inputRef} showTime={false} style={inputWidth} onFocus={handleFocus} onBlur={handleBlur} allowClear placeholder={['Start Date', 'End Date']} />
      <label style={isLabelTop ? styles.labelFocused : styles.labelUnfocused}>
        <span>{label}</span>
      </label>
    </div>
  );
};
