/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Modal, notification, Row, Table, TableColumnsType, TableProps, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import { LottieButton } from 'components/Create/Products/ProductsGrid/components/LottieButton';
import dayjs from 'dayjs';

import { useAuthentication } from 'hooks/useAuthentication';
import { Component, KitResponse } from 'models/Kit';
import { Product } from 'models/Product';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeleteKitMutation } from 'redux/services/gonzo/gonzoApi';
import { useAppSelector } from 'redux/store';
import deleteLottie from '../../assets/delete.json';

type Props = TableProps & {
  isCreate: boolean;
  subComponents?: Component[];
};

export type TableProduct = Partial<Product> & {
  lineType: string;
  componentQuantity: number;
};

export const LineItemTable: FC<Props> = ({ isCreate, subComponents, ...rest }) => {
  const nav = useNavigate();
  const { acuityContext, user } = useAppSelector((state) => state.app);
  const divId = acuityContext?.selectedCustomer.id;
  // const isAdmin = user && user['https://acuity.mdsiinc.com/user/user_metadata'].is_mdsi_employee;
  const { isAdmin } = useAuthentication();

  const [deleteRecord, { isLoading }] = useDeleteKitMutation();

  const handleDelete = async (record: KitResponse): Promise<void> => {
    try {
      await deleteRecord({ kitId: record.id as string, divisionId: divId as string });
      notification.success({ message: `Kit successfully deleted` });
    } catch (error) {
      console.log(error);
      const errorMessage = (error as { data: { errorMessage: string } }).data?.errorMessage ?? 'Error creating kit';

      notification.error({ message: errorMessage });
    }
  };

  const columns: ColumnType<KitResponse>[] = [
    {
      title: 'Kit ID',
      dataIndex: 'kitId',
      key: 'kitID',
      render: (text, record) => (
        <Button type="link" onClick={() => nav(`${record.id}/edit`)}>
          {text}
        </Button>
      )
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: '# of Components',
      dataIndex: 'componentQuantity',
      key: 'componentQuantity',
      render: (text, record) => <Typography.Text>{record.components.length}</Typography.Text>
    },
    {
      title: 'Last Updated (UTC)',
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      render: (text, record) => {
        const formattedDate = dayjs(record.modifiedDateTime).format('YYYY-MM-DD HH:mm');

        return <span>{formattedDate}</span>;
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (text, record) => {
        return (
          <LottieButton
            animationData={deleteLottie}
            onClick={() => Modal.confirm({ title: 'Are you sure you want to delete this kit? This action will permanently erase it.', onOk: () => handleDelete(record) })}
          />
        );
      }
    }
  ];

  const expandedRowRenderSub = (record: Component) => {
    const columns: TableColumnsType<any> = [
      {
        title: 'Product Number',
        dataIndex: 'itemId',
        key: 'itemId',
        width: '30ch'
      },
      {
        title: 'Alt Item Id',
        dataIndex: 'alternateItemId',
        key: 'alternateItemId',
        width: '20ch'
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        ellipsis: true
      },
      {
        title: 'Manufacturer',
        dataIndex: 'manufacturer',
        key: 'manufacturer',
        width: '15ch'
      },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        width: '15ch'
      },
      {
        title: 'Qty',
        dataIndex: 'componentQuantity',
        align: 'right',
        width: '5ch',
        key: 'componentQuantity'
      }
    ];

    return (
      <Row style={{ paddingTop: 25, paddingBottom: 25 }}>
        <Table style={{ width: '100%' }} columns={columns} dataSource={record.subComponents} pagination={false} />
      </Row>
    );
  };

  const expandedRowRender = (record: KitResponse) => {
    const columns: TableColumnsType<any> = [
      {
        title: 'Product Number',
        dataIndex: 'itemId',
        key: 'itemId',
        width: '30ch'
      },
      {
        title: 'Alt Item Id',
        dataIndex: 'alternateItemId',
        key: 'alternateItemId',
        width: '20ch'
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        ellipsis: true
      },
      {
        title: 'Manufacturer',
        dataIndex: 'manufacturer',
        key: 'manufacturer',
        width: '15ch'
      },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        width: '15ch'
      },
      {
        title: 'Qty',
        dataIndex: 'componentQuantity',
        align: 'right',
        width: '5ch',
        key: 'componentQuantity'
      }
    ];

    return (
      <Table
        style={{ width: '100%' }}
        columns={columns}
        dataSource={record.components}
        rowKey={(rec) => rec.itemId ?? ''}
        pagination={false}
        expandable={{ expandedRowRender: expandedRowRenderSub, rowExpandable: (rec) => rec?.subComponents?.length > 0 }}
      />
    );
  };

  const filteredCols = isAdmin ? columns : columns.filter((col) => col.key !== 'actions');

  return (
    // <Spin spinning={isLoading} indicator={<Loader loadingMessage="Deleting kit..." />}>
    <Table
      expandable={{
        expandedRowRender: isCreate ? undefined : expandedRowRender,
        rowExpandable: (record) => record?.components?.length > 0
      }}
      rowKey={(record) => record.kitId}
      bordered
      columns={filteredCols}
      {...rest}
      size="small"
    />
    // </Spin>
  );
};
