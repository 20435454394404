import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Button, Drawer, List, Skeleton, Space, Typography } from 'antd';
import { Loader } from 'components/common/Loader';
import WimsicalError from 'components/common/WimsicalError/WimsicalError';
import { CategoryFilter, ManufacturerFilter, ProductNameFilter } from 'components/Create/Products/HeaderFilters/components';
import { FC, Fragment, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { useGetProductsQuery } from 'redux/services/julia/juliaApi';
import { setOffset } from 'redux/services/julia/productsParams';
import { ReduxState, useAppSelector } from 'redux/store';
import { colors, toRgba } from 'styles/colors';
import { ProductItem } from './ProductItem';

export const AddComponentButton: FC = () => {
  const [open, setOpen] = useState(false);
  const { juliaProductsParams } = useAppSelector((state: ReduxState) => state);
  const dispatch = useDispatch();

  const { data, isLoading, isFetching, isError, error } = useGetProductsQuery(juliaProductsParams);
  const errData = error as FetchBaseQueryError;

  const handleNext = (): void => {
    dispatch(setOffset(juliaProductsParams.offset + 25));
  };

  return (
    <Fragment>
      <Button onClick={(): void => setOpen(true)} icon={<PlusCircleOutlined />}>
        Add Main Components
      </Button>

      <Drawer
        destroyOnClose
        width={900}
        title={
          <Typography.Title style={{ margin: 0, padding: 0 }} level={5}>
            Add Main Components
          </Typography.Title>
        }
        styles={{ header: { background: toRgba(colors.royalBlueLight, 0.2) } }}
        placement="right"
        size={'large'}
        onClose={(): void => setOpen(false)}
        open={open}
        closable={false}
        extra={
          <CloseOutlined
            onClick={(): void => {
              setOpen(false);
            }}
          />
        }>
        {isError && (
          <WimsicalError
            title={(errData.data as string) || 'Oops something went wrong'}
            statusCode={errData.status}
            subTitle={'Please try to reload the app and try again'}
            redirectText={'Reload'}
            redirectUrl={process.env.REACT_APP_REDIRECT_URL}
          />
        )}
        {!isError && (
          <>
            <Space style={{ marginBottom: 16 }}>
              <ProductNameFilter />
              <ManufacturerFilter />
              <CategoryFilter />
            </Space>
            <InfiniteScroll
              scrollableTarget="scrollableDiv"
              height={'85vh'}
              next={handleNext}
              dataLength={data?.data?.length ?? 0}
              hasMore={(data && data.data.length < data.totalCount) ?? false}
              loader={<Skeleton active />}>
              <div className="scrollableDiv">
                <List
                  loading={{ spinning: isLoading || (isFetching && !juliaProductsParams.offset), indicator: <Loader loadingMessage="Loading products..." /> }}
                  dataSource={data?.data ?? []}
                  renderItem={(item): JSX.Element => <ProductItem product={item} />}
                />
              </div>
            </InfiniteScroll>
          </>
        )}
      </Drawer>
    </Fragment>
  );
};
