import { FancySelect } from 'components/ui/Inputs';
import { Country } from 'country-state-city';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setSubdivisionLocalCodeEquals } from 'redux/services/chuckieSue/addressesParams';
import { setCountry } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';

export const CountryFilter: React.FC = () => {
  const dispatch = useDispatch();
  const { country: countryTest } = useAppSelector((state) => state.app);

  const countries = Country.getAllCountries();

  const countryNames = countries.map((country) => ({ label: country.name, value: country.isoCode }));

  const handleCountryChange = (value: string): void => {
    dispatch(setCountry(value));
    dispatch(setSubdivisionLocalCodeEquals(undefined));
  };

  return <FancySelect placeholder="Country" style={{ margin: 0 }} value={countryTest} onChange={handleCountryChange} showSearch options={countryNames ? countryNames : undefined} />;
};
