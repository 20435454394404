import { Space, Switch } from 'antd';
import { SwitchProps } from 'antd/lib';
import { Field, FieldProps, useFormikContext } from 'formik';
import { FC } from 'react';
import { FormLabel } from './FormLabel';

interface Props extends SwitchProps {
  fieldName: string;
  placeholder?: string;
  label: string;
}

export const FieldInputSwitch: FC<Props> = ({ fieldName, placeholder, label, ...rest }) => {
  const { handleSubmit } = useFormikContext();

  return (
    <Field name={fieldName} placeholder={placeholder}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => {
        return (
          <Space direction="vertical">
            <FormLabel label={label} />
            <Switch style={{ borderColor: meta.error && meta.touched ? '#dc3545' : undefined }} checked={meta.value} onChange={(e) => setFieldValue(fieldName, e)} {...rest} />
            <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
          </Space>
        );
      }}
    </Field>
  );
};
