import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'js-cookie';
import { AcuityContext } from 'models/Application';
import { useEffect, useState } from 'react';
import { setAccessToken, setAcuityContext, setUser } from 'redux/slices/appSlice';
import { useAppDispatch, useAppSelector } from 'redux/store';

export const useAuthentication = (): { isLoading: boolean; accessToken: string | null; cookieNotFound: boolean; permissions: string[]; isAdmin: boolean } => {
  const { accessToken } = useAppSelector((state) => state.app);
  const [permissions, setPermissions] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const { isAuthenticated, loginWithRedirect, isLoading, user, getAccessTokenSilently } = useAuth0();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const [cookieNotFound, setCookieNotFound] = useState<boolean>(false);

  // Check if User is logged in, if not, redirect them to login screen
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({ appState: { targetUrl: '/' } });
    }
    if (isAuthenticated && accessToken === null) {
      getAccessTokenSilently().then((accessToken) => dispatch(setAccessToken(accessToken)));
    }
  }, [accessToken, dispatch, getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect]);

  useEffect(() => {
    const cookie = Cookies.get(`${process.env.REACT_APP_COOKIE_PREFIX}_acuity_context`);
    const cookieJSON: AcuityContext = cookie ? JSON.parse(cookie) : undefined;

    if (cookieJSON?.selectedCustomer) {
      dispatch(setAcuityContext(cookieJSON));
    } else {
      setCookieNotFound(true);
    }

    if (cookieNotFound) {
      setTimeout(() => {
        window.location.href = process.env.REACT_APP_ERNIE_REDIRECT_URL || '';
      }, 5000);
    }
  }, [cookieNotFound, dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
    }
    if (accessToken) {
      const payload = JSON.parse(window.atob(accessToken.split('.')[1]));
      const permissionsPayload = payload.permissions.filter((permission: string) => permission.split(':')[0] === 'useApplication' || permission.split(':')[0] === 'useApplciation');

      if (payload.permissions.includes('internalAdmin:nonAssembledKits') || payload.permissions.includes('superAdmin') || payload.permissions.includes('customerAdmin:nonAssembledKits')) {
        setIsAdmin(true);
      }

      setPermissions(permissionsPayload);
    }
  }, [accessToken, user]);

  return { isLoading, accessToken, cookieNotFound, permissions, isAdmin };
};
