/* eslint-disable no-debugger */
import { List } from 'antd';
import { mockData } from 'api/MockSampleData';
import WimsicalError from 'components/common/WimsicalError/WimsicalError';
import { formatErrorMessage } from 'helpers/formatErrorMessage';
import { QueryErrorModel } from 'models/ErrorModel';
import { useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGetSparingRequestConfigurationTypesQuery } from 'redux/services/calvin/sparingRequestConfigTypes';
import { incrementOffset, resetParams } from 'redux/services/twittlebugs/sparingParams';
import { useGetSparingRequestsQuery } from 'redux/services/twittlebugs/sparingRequestsApi';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { ERCard } from './ERCard';

export const CardGrid = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { sparingRequestsParams } = useAppSelector((state) => state);
  const { data, isError, isLoading, isFetching, error } = useGetSparingRequestsQuery(sparingRequestsParams);
  const { data: typeData, isLoading: typeIsLoading, isError: typeIsError, error: configError } = useGetSparingRequestConfigurationTypesQuery();
  const dispatch = useAppDispatch();

  const ref = useRef<InfiniteScroll>(null);

  const handleGetMore = (): void => {
    dispatch(incrementOffset());
  };

  useEffect(() => {
    return () => {
      dispatch(resetParams());
    };
  }, []);

  // if (isLoading || typeIsLoading || (isFetching && sparingRequestsParams.offset === 0)) return <Loader loadingMessage="Loading Sparing Requests" />;

  if (isError) {
    const err = error as QueryErrorModel;

    return <WimsicalError title={formatErrorMessage(typeof err.status === 'number' ? err.status : 400)} subTitle={err?.data?.errorMessage ? err.data.errorMessage : ''} statusCode={err.status} />;
  }

  // if (data?.data.length === 0) {
  //   return <NoResults errorMessage="No Results Were Found..." />;
  // }

  return (
    <List
      dataSource={mockData}
      grid={{ gutter: 16, column: 4 }}
      renderItem={(item): JSX.Element => (
        <List.Item>
          <ERCard equipmentReturn={item} />
        </List.Item>
      )}
    />
  );
};
