import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Button, Col, Dropdown, MenuProps, notification, Row, Space, Spin, Typography } from 'antd';
import { AddComponentButton } from 'components/atoms/AddComponentButton';
import { Loader } from 'components/common/Loader';
import { WaitToSearch } from 'components/common/waitToSearch/WaitToSearch';
import WimsicalError from 'components/common/WimsicalError/WimsicalError';
import { KitForm } from 'components/molecules/KitForm';
import { PartsTable } from 'components/organisms/PartsTable';
import { FormikProvider, useFormik } from 'formik';
import { useAuthentication } from 'hooks/useAuthentication';
import { KitPayload, KitValidationSchema } from 'models/Kit';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetKitQuery, useUpdateKitMutation } from 'redux/services/gonzo/gonzoApi';
import { useAppSelector } from 'redux/store';
import { colors, toRgba } from 'styles/colors';
import tableLottie from '../assets/empty-table.json';
import { ColorCard } from './Create/ColorCard';

export const EditKitPage = (): JSX.Element => {
  const { id } = useParams();
  const { acuityContext, user } = useAppSelector((state) => state.app);
  const divisionId = acuityContext?.selectedCustomer.id ?? '';
  // const isAdmin = user && user['https://acuity.mdsiinc.com/user/user_metadata'].is_mdsi_employee;
  const { isAdmin } = useAuthentication();

  const [isClose, setIsClose] = useState(false);

  const nav = useNavigate();

  const [updateKit] = useUpdateKitMutation();

  const { data, isLoading, isFetching, isError, error } = useGetKitQuery({ divisionId: divisionId as string, kitId: id as string }, { skip: !id || !divisionId });
  const formik = useFormik<KitPayload>({
    enableReinitialize: true,
    validationSchema: KitValidationSchema,
    initialValues: {
      kitId: data?.kitId ?? '',
      description: data?.description ?? '',
      divisionId: data?.divisionMetadata.id ?? '',
      isActive: data?.isActive ?? true,
      components: data?.components ?? []
    },
    onSubmit: async (values) => {
      try {
        const resp = await updateKit({ divisionId: divisionId as string, kitId: id as string, payload: values }).unwrap();

        notification.success({ message: `${resp.kitId}created successfully` });
        if (isClose) {
          nav('/');
        }
      } catch (error) {
        console.error(error);
        const errorMessage = (error as { data: { errorMessage: string } }).data?.errorMessage ?? 'Error creating kit';

        notification.error({ message: errorMessage });
      }
    }
  });
  const items: MenuProps['items'] = [
    {
      label: 'Save',
      key: '1',
      onClick: () => {
        setIsClose(false);
        formik.submitForm();
      }
    }
  ];

  const renderEmpty = () => {
    if (data && !isLoading && !isFetching) {
      if (data.components.length && !formik.values.components.length && formik.dirty) {
        return <WaitToSearch animationData={tableLottie} message="Add components" />;
      }
      if (!data.components.length) {
        return <WaitToSearch animationData={tableLottie} message="Add components" />;
      }
    }

    return null;
  };

  if (isError) {
    const errData = error as FetchBaseQueryError;

    return (
      <WimsicalError
        title={(errData.data as string) || 'Oops something went wrong'}
        statusCode={errData.status}
        subTitle={'Please try to reload the app and try again'}
        redirectText={'Reload'}
        redirectUrl={process.env.REACT_APP_REDIRECT_URL}
      />
    );
  }

  return (
    <FormikProvider value={formik}>
      <Spin spinning={isLoading || isFetching || formik.isSubmitting} indicator={<Loader loadingMessage={formik.isSubmitting ? 'Updating kit...' : 'Loading kit...'} />}>
        <Space direction="vertical" size={10} style={{ width: '100%' }}>
          <Row justify={'space-between'}>
            <Typography.Title style={{ margin: 0, padding: 0 }} level={4}>
              Edit Kit
            </Typography.Title>
            <Col>
              <Space>
                <Button icon={<ArrowLeftOutlined />} onClick={(): void => nav('/')}>
                  Back
                </Button>
                {isAdmin && (
                  <Dropdown.Button
                    onClick={() => {
                      setIsClose(true);
                      formik.submitForm();
                    }}
                    icon={<SaveOutlined />}
                    menu={{ items }}
                    type="primary">
                    Save & Close
                  </Dropdown.Button>
                )}
              </Space>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col sm={24} md={24} lg={24} xl={6} xxl={6}>
              <ColorCard title={'Header Information'} color={toRgba(colors.illuminatingEmerald, 0.6)}>
                <KitForm />
              </ColorCard>
            </Col>
            <Col sm={24} md={24} lg={24} xl={18} xxl={18}>
              <ColorCard
                extra={isAdmin ? <AddComponentButton /> : null}
                title={
                  <Space align="center">
                    <Typography.Title style={{ margin: 0, padding: 0 }} level={4}>
                      Components
                    </Typography.Title>
                    <Typography.Title level={4} style={{ margin: 0, padding: 0 }}>
                      ({formik.values.components.length})
                    </Typography.Title>
                  </Space>
                }
                color={toRgba(colors.orangeWeb, 0.4)}>
                <PartsTable
                  locale={{
                    emptyText: renderEmpty()
                  }}
                />
              </ColorCard>
            </Col>
          </Row>
        </Space>
      </Spin>
    </FormikProvider>
  );
};
