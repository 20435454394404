/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Form, Row, Space, Table } from 'antd';
import { ColumnType, TableProps } from 'antd/es/table';
import { AddSubComponentButton } from 'components/atoms/AddSubComponentButton';
import { LottieButton } from 'components/Create/Products/ProductsGrid/components/LottieButton';
import { useFormikContext } from 'formik';
import { useAuthentication } from 'hooks/useAuthentication';
import { Component, KitPayload } from 'models/Kit';
import { FC } from 'react';
import { useAppSelector } from 'redux/store';
import deleteLottie from '../../assets/delete.json';

export const PartsTable: FC<TableProps> = ({ ...rest }) => {
  const { values, setFieldValue } = useFormikContext<Partial<KitPayload>>();
  const { acuityContext, user } = useAppSelector((state) => state.app);

  const [form] = Form.useForm();
  // const isAdmin = user && user['https://acuity.mdsiinc.com/user/user_metadata'].is_mdsi_employee;
  const { isAdmin } = useAuthentication();

  const columns: ColumnType<Component>[] = [
    {
      title: 'Product Number',
      dataIndex: 'itemId',
      key: 'itemId',
      width: '30ch'
    },
    {
      title: 'Alt Item Id',
      dataIndex: 'alternateItemId',
      key: 'alternateItemId',
      width: '20ch'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true
    },
    {
      title: 'Manufacturer',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      width: '15ch'
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: '15ch'
    },
    {
      title: 'Qty',
      dataIndex: 'componentQuantity',
      align: 'right',
      width: '5ch',
      key: 'componentQuantity'
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      width: '15ch',

      render: (text, record) => (
        <Space>
          <AddSubComponentButton record={record} />
          <LottieButton
            animationData={deleteLottie}
            onClick={() => {
              setFieldValue(
                'components',
                (values.components ?? []).filter((component) => component.itemId !== record.itemId)
              );
            }}
          />
        </Space>
      )
    }
  ];

  const expandedRowRender = (record: Component) => {
    const columns: ColumnType<Component>[] = [
      {
        title: 'Product Number',
        dataIndex: 'itemId',
        key: 'itemId',
        width: '30ch'
      },
      {
        title: 'Alt Item Id',
        dataIndex: 'alternateItemId',
        key: 'alternateItemId',
        width: '20ch'
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        ellipsis: true
      },
      {
        title: 'Manufacturer',
        dataIndex: 'manufacturer',
        key: 'manufacturer',
        width: '15ch'
      },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        width: '15ch'
      },
      {
        title: 'Qty',
        dataIndex: 'componentQuantity',
        align: 'right',
        width: '5ch',
        key: 'componentQuantity'
      },
      {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        width: '15ch',

        render: (text, subRecord) => {
          const parent = values.components?.find((component) => component.itemId === record.itemId);

          return (
            <LottieButton
              animationData={deleteLottie}
              onClick={() => {
                setFieldValue(
                  `components[${values.components?.indexOf(record)}].subComponents`,
                  parent?.subComponents?.filter((component) => component.itemId !== subRecord.itemId)
                );
              }}
            />
          );
        }
      }
    ];
    const filteredCols = isAdmin ? columns : columns.filter((col) => col.key !== 'actions');

    return (
      <Row style={{ paddingTop: 25, paddingBottom: 25 }}>
        <Table bordered style={{ width: '100%' }} columns={filteredCols} dataSource={record.subComponents} pagination={false} />
      </Row>
    );
  };

  const filteredCols = isAdmin ? columns : columns.filter((col) => col.key !== 'actions');

  return (
    <Form form={form} component={false}>
      <Table
        size="small"
        bordered
        dataSource={values.components}
        expandable={{ expandedRowRender, rowExpandable: (record: Component) => record.subComponents?.length > 0 }}
        rowKey={(record) => record.itemId ?? ''}
        columns={filteredCols}
        pagination={false}
        {...rest}
      />
    </Form>
  );
};
