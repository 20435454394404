import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Col, Row, Space, Spin, Typography } from 'antd';
import { ImportButton } from 'components/atoms/ImportButton';
import { Loader } from 'components/common/Loader';
import { WaitToSearch } from 'components/common/waitToSearch/WaitToSearch';
import WimsicalError from 'components/common/WimsicalError/WimsicalError';
import { LineItemTable } from 'components/organisms/LineItemTable';
import { FancyInput } from 'components/ui/Inputs';
import { useAuthentication } from 'hooks/useAuthentication';
import { useDispatch } from 'react-redux';
import { useGetKitsQuery } from 'redux/services/gonzo/gonzoApi';
import { setDescriptionContains, setKitIdContains } from 'redux/services/gonzo/gonzoParams';
import { useAppSelector } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';
import tableLottie from '../assets/empty.json';

export const Home = (): JSX.Element => {
  const { gonzoParams } = useAppSelector((state) => state);
  const { acuityContext, importState, user } = useAppSelector((state) => state.app);
  const divId = acuityContext?.selectedCustomer.id ?? '';
  const { data, isLoading, isFetching, isError, error } = useGetKitsQuery({ divisionId: divId as string, params: gonzoParams }, { skip: !divId });
  // const isAdmin = user && user['https://acuity.mdsiinc.com/user/user_metadata'].is_mdsi_employee;
  const { isAdmin } = useAuthentication();

  const dispatch = useDispatch();

  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch(setKitIdContains(value || undefined));
  }, 500);

  const handleChangeDesc = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch(setDescriptionContains(value || undefined));
  }, 500);

  if (isError) {
    const errData = error as FetchBaseQueryError;

    return (
      <WimsicalError
        title={(errData.data as string) || 'Oops something went wrong'}
        statusCode={errData.status}
        subTitle={'Please try to reload the app and try again'}
        redirectText={'Reload'}
        redirectUrl={process.env.REACT_APP_REDIRECT_URL}
      />
    );
  }

  return (
    <Spin spinning={importState.isImporting} indicator={<Loader loadingMessage={importState.importMessage} />}>
      <Space size={10} direction="vertical" style={{ width: '100%' }}>
        <Row justify={'space-between'}>
          <Typography.Title style={{ padding: 0, margin: 0 }} level={4}>
            Search Kits
          </Typography.Title>
          {isAdmin && <ImportButton />}
        </Row>
        <Row style={{ padding: 16, background: 'white' }}>
          <Space>
            <FancyInput placeholder="Kit Id" onChange={handleChange} />
            <FancyInput placeholder="Kit Description" onChange={handleChangeDesc} />
          </Space>
        </Row>
        <Row>
          <Col span={24}>
            <LineItemTable
              dataSource={data?.data}
              loading={{ spinning: isLoading || isFetching, indicator: <Loader medium /> }}
              isCreate={false}
              locale={{
                emptyText: !isLoading ? <WaitToSearch animationData={tableLottie} /> : null
              }}
            />
          </Col>
        </Row>
      </Space>
    </Spin>
  );
};
