import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, InputNumber, List, Row } from 'antd';
import { useFormikContext } from 'formik';
import { Component, KitPayload } from 'models/Kit';
import { Product } from 'models/Product';
import { FC, useState } from 'react';

type Props = {
  product: Product;
};
export const ProductItem: FC<Props> = ({ product }) => {
  const { setValues, values } = useFormikContext<KitPayload>();
  const [type, setType] = useState<{ lineType: string; qty: number }>({
    lineType: 'Main',
    qty: 1
  });

  const handleAdd = (product: Product): void => {
    const payload: Component = {
      lineType: type.lineType,
      itemId: product.productNumber,
      alternateItemId: product?.customerXRef?.alternateItemId ?? '',
      description: product.productDescription,
      manufacturer: product.manufacturer,
      category: product.category,
      componentQuantity: type.qty,
      subComponents: []
    };

    setValues((prev) => {
      return {
        ...prev,
        components: [...prev.components, payload]
      };
    });
    setType({ lineType: 'Main', qty: 1 });
  };

  return (
    <List.Item style={{ width: '100%' }}>
      <Row gutter={[16, 0]} style={{ width: '100%' }} align={'middle'}>
        <Col span={4}>{product.productNumber}</Col>
        <Col span={4}>{product?.customerXRef?.alternateItemId ?? 'N/A'}</Col>
        <Col span={4}>{product.manufacturer}</Col>
        <Col span={4}>{product.category}</Col>
        <Col span={4}>
          <InputNumber
            onKeyDown={(e): void => {
              if (!e.code.includes('Digit') && !e.code.includes('Backspace')) {
                e.preventDefault();

                return;
              }
            }}
            min={1}
            value={type.qty}
            onChange={(e): void => {
              if (!e) return;
              setType({ ...type, qty: e as number });
            }}
            controls={false}
            style={{ width: 70 }}
            placeholder="Qty"
          />
        </Col>
        <Col span={4}>
          <Row justify={'end'}>
            <Button disabled={values?.components?.some((val) => val.itemId === product.productNumber)} onClick={(): void => handleAdd(product)} size="small" type="primary" icon={<PlusOutlined />} />
          </Row>
        </Col>
      </Row>
    </List.Item>
  );
};
