export const mockData = [
  {
    referenceInformation: {
      returnType: 'Equipment Pickup',
      referenceNumber: 'SYp-81330695',
      requestor: {
        requestorName: 'Stephen Ford',
        requestorPhone: '249-535-8937',
        requestorEmail: 'uwolfe@hotmail.com'
      },
      requestedPickupDate: '05/15/2024',
      requestedPickupTime: '02:57:16'
    },
    pickupAddress: {
      address: '69415 Julia Village\nLake Marcfort, SC 57823',
      manualAddressEntryAllowed: true
    },
    siteContact: {
      contactName: 'Benjamin Dennis',
      contactPhone: '(555) 555-5555',
      contactEmail: 'contact@gmail.com',
      siteSpecificInstructions: 'Offer serious know.',
      emailNotificationList: ['patrickpacheco@orr.com', 'stacy19@gmail.com', 'littleglenn@hotmail.com']
    },
    otherSiteInformation: {
      freightElevator: 'Yes',
      dockDoors: 'No',
      equipmentLists: {
        attachments: 'seem.pdf',
        valueOfEquipment: true
      },
      isEquipmentPackagedPalletized: true
    },
    packageInformation: {
      packageSize: 'L',
      packageWeight: 58.53537288967327,
      numberOfPackages: 2
    },
    createWaybill: 'Functionality to call FEDEX API',
    returnPDFs: 'Functionality to return PDFs for printing',
    savePDFsWithTheOrder: 'Functionality to save PDFs'
  },
  {
    referenceInformation: {
      returnType: 'Equipment Pickup',
      referenceNumber: 'SYp-81330695',
      requestor: {
        requestorName: 'Stephen Ford',
        requestorPhone: '249-535-8937',
        requestorEmail: 'uwolfe@hotmail.com'
      },
      requestedPickupDate: '05/15/2024',
      requestedPickupTime: '02:57:16'
    },
    pickupAddress: {
      address: '69415 Julia Village\nLake Marcfort, SC 57823',
      manualAddressEntryAllowed: true
    },
    siteContact: {
      contactName: 'Benjamin Dennis',
      contactPhone: '(555) 555-5555',
      contactEmail: 'contact@gmail.com',
      siteSpecificInstructions: 'Offer serious know.',
      emailNotificationList: ['patrickpacheco@orr.com', 'stacy19@gmail.com', 'littleglenn@hotmail.com']
    },
    otherSiteInformation: {
      freightElevator: 'Yes',
      dockDoors: 'No',
      equipmentLists: {
        attachments: 'seem.pdf',
        valueOfEquipment: true
      },
      isEquipmentPackagedPalletized: true
    },
    packageInformation: {
      packageSize: 'XL',
      packageWeight: 175.5353,
      numberOfPackages: 13
    },
    createWaybill: 'Functionality to call FEDEX API',
    returnPDFs: 'Functionality to return PDFs for printing',
    savePDFsWithTheOrder: 'Functionality to save PDFs'
  },
  {
    referenceInformation: {
      returnType: 'Equipment Pickup',
      referenceNumber: 'SYp-81330695',
      requestor: {
        requestorName: 'Stephen Ford',
        requestorPhone: '249-535-8937',
        requestorEmail: 'uwolfe@hotmail.com'
      },
      requestedPickupDate: '05/15/2024',
      requestedPickupTime: '02:57:16'
    },
    pickupAddress: {
      address: '69415 Julia Village\nLake Marcfort, SC 57823',
      manualAddressEntryAllowed: true
    },
    siteContact: {
      contactName: 'Benjamin Dennis',
      contactPhone: '(555) 555-5555',
      contactEmail: 'contact@gmail.com',
      siteSpecificInstructions: 'Offer serious know.',
      emailNotificationList: ['patrickpacheco@orr.com', 'stacy19@gmail.com', 'littleglenn@hotmail.com']
    },
    otherSiteInformation: {
      freightElevator: 'Yes',
      dockDoors: 'No',
      equipmentLists: {
        attachments: 'seem.pdf',
        valueOfEquipment: true
      },
      isEquipmentPackagedPalletized: true
    },
    packageInformation: {
      packageSize: 'S',
      packageWeight: 12.53537288967327,
      numberOfPackages: 3
    },
    createWaybill: 'Functionality to call FEDEX API',
    returnPDFs: 'Functionality to return PDFs for printing',
    savePDFsWithTheOrder: 'Functionality to save PDFs'
  },
  {
    referenceInformation: {
      returnType: 'Equipment Pickup',
      referenceNumber: 'SYp-81330695',
      requestor: {
        requestorName: 'Stephen Ford',
        requestorPhone: '249-535-8937',
        requestorEmail: 'uwolfe@hotmail.com'
      },
      requestedPickupDate: '05/15/2024',
      requestedPickupTime: '02:57:16'
    },
    pickupAddress: {
      address: '69415 Julia Village\nLake Marcfort, SC 57823',
      manualAddressEntryAllowed: true
    },
    siteContact: {
      contactName: 'Benjamin Dennis',
      contactPhone: '(555) 555-5555',
      contactEmail: 'contact@gmail.com',
      siteSpecificInstructions: 'Offer serious know.',
      emailNotificationList: ['patrickpacheco@orr.com', 'stacy19@gmail.com', 'littleglenn@hotmail.com']
    },
    otherSiteInformation: {
      freightElevator: 'Yes',
      dockDoors: 'No',
      equipmentLists: {
        attachments: 'seem.pdf',
        valueOfEquipment: true
      },
      isEquipmentPackagedPalletized: true
    },
    packageInformation: {
      packageSize: 'M',
      packageWeight: 60.53537288967327,
      numberOfPackages: 4
    },
    createWaybill: 'Functionality to call FEDEX API',
    returnPDFs: 'Functionality to return PDFs for printing',
    savePDFsWithTheOrder: 'Functionality to save PDFs'
  }
];

export type MockType = typeof mockData['0'];

export const testData: any[] = [
  {
    kitID: '1',
    kitDescription: 'Kit 1 Description',
    isActive: true,
    lineType: 'Type A',
    itemID: 'Item 1',
    componentQuantity: 10,
    subComponents: [
      {
        kitID: '1.1',
        kitDescription: 'Kit 1.1 Description',
        isActive: true,
        lineType: 'Type A',
        itemID: 'Item 1.1',
        componentQuantity: 11
      },
      {
        kitID: '1.2',
        kitDescription: 'Kit 1.2 Description',
        isActive: true,
        lineType: 'Type A',
        itemID: 'Item 1.2',
        componentQuantity: 12
      },
      {
        kitID: '1.3',
        kitDescription: 'Kit 1.3 Description',
        isActive: true,
        lineType: 'Type A',
        itemID: 'Item 1.3',
        componentQuantity: 13
      }
    ]
  },
  {
    kitID: '2',
    kitDescription: 'Kit 2 Description',
    isActive: false,
    lineType: 'Type B',
    itemID: 'Item 2',
    componentQuantity: 20,
    subComponents: [
      {
        kitID: '1.1',
        kitDescription: 'Kit 1.1 Description',
        isActive: true,
        lineType: 'Type A',
        itemID: 'Item 1.1',
        componentQuantity: 11
      },
      {
        kitID: '1.2',
        kitDescription: 'Kit 1.2 Description',
        isActive: true,
        lineType: 'Type A',
        itemID: 'Item 1.2',
        componentQuantity: 12
      },
      {
        kitID: '1.3',
        kitDescription: 'Kit 1.3 Description',
        isActive: true,
        lineType: 'Type A',
        itemID: 'Item 1.3',
        componentQuantity: 13
      }
    ]
  },
  {
    kitID: '3',
    kitDescription: 'Kit 3 Description',
    isActive: true,
    lineType: 'Type C',
    itemID: 'Item 3',
    componentQuantity: 30,
    subComponents: [
      {
        kitID: '1.1',
        kitDescription: 'Kit 1.1 Description',
        isActive: true,
        lineType: 'Type A',
        itemID: 'Item 1.1',
        componentQuantity: 11
      },
      {
        kitID: '1.2',
        kitDescription: 'Kit 1.2 Description',
        isActive: true,
        lineType: 'Type A',
        itemID: 'Item 1.2',
        componentQuantity: 12
      },
      {
        kitID: '1.3',
        kitDescription: 'Kit 1.3 Description',
        isActive: true,
        lineType: 'Type A',
        itemID: 'Item 1.3',
        componentQuantity: 13
      }
    ]
  },
  {
    kitID: '4',
    kitDescription: 'Kit 4 Description',
    isActive: false,
    lineType: 'Type D',
    itemID: 'Item 4',
    componentQuantity: 40,
    subComponents: [
      {
        kitID: '1.1',
        kitDescription: 'Kit 1.1 Description',
        isActive: true,
        lineType: 'Type A',
        itemID: 'Item 1.1',
        componentQuantity: 11
      },
      {
        kitID: '1.2',
        kitDescription: 'Kit 1.2 Description',
        isActive: true,
        lineType: 'Type A',
        itemID: 'Item 1.2',
        componentQuantity: 12
      },
      {
        kitID: '1.3',
        kitDescription: 'Kit 1.3 Description',
        isActive: true,
        lineType: 'Type A',
        itemID: 'Item 1.3',
        componentQuantity: 13
      }
    ]
  },
  {
    kitID: '5',
    kitDescription: 'Kit 5 Description',
    isActive: true,
    lineType: 'Type E',
    itemID: 'Item 5',
    componentQuantity: 50,
    subComponents: [
      {
        kitID: '1.1',
        kitDescription: 'Kit 1.1 Description',
        isActive: true,
        lineType: 'Type A',
        itemID: 'Item 1.1',
        componentQuantity: 11
      },
      {
        kitID: '1.2',
        kitDescription: 'Kit 1.2 Description',
        isActive: true,
        lineType: 'Type A',
        itemID: 'Item 1.2',
        componentQuantity: 12
      },
      {
        kitID: '1.3',
        kitDescription: 'Kit 1.3 Description',
        isActive: true,
        lineType: 'Type A',
        itemID: 'Item 1.3',
        componentQuantity: 13
      }
    ]
  }
];
