import { About } from 'pages/About';
import { CreateKitPage } from 'pages/CreateKitPage';
import { EditKitPage } from 'pages/EditKitPage';
import { Home } from 'pages/Home';
import { SearchPage } from 'pages/Search';
import { Route, Routes } from 'react-router-dom';

export const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/create" element={<CreateKitPage />} />
      <Route path="/:id/edit" element={<EditKitPage />} />
      <Route path="/search" element={<SearchPage />} />
      <Route path="/about" element={<About />} />
    </Routes>
  );
};
