import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Tabs, Tooltip, Typography } from 'antd';
import Upload from 'antd/es/upload/Upload';
import { TabsProps } from 'antd/lib';
import { RcFile } from 'antd/lib/upload';
import { KitPayload } from 'models/Kit';
import React from 'react';
import { ComponentLineError, SubComponentLineError, useImportKitsMutation } from 'redux/services/gonzo/gonzoApi';
import { useAppSelector } from 'redux/store';
import { read, utils, writeFile } from 'xlsx';

export type LineItemErrorObjectAsset = {
  kitD: string;
  errorMessage: string;
};

export const ImportButton: React.FC = () => {
  const { acuityContext } = useAppSelector((state) => state.app);
  const divisionId = acuityContext?.selectedCustomer.id ?? '';
  /* ******************** Hooks ******************** */

  const [triggerImport] = useImportKitsMutation();

  const handleErrorModal = (importErrors: LineItemErrorObjectAsset[], componentErrors: ComponentLineError[], subComponentErrors: SubComponentLineError[]): void => {
    const findTab = (): string => {
      if (!importErrors.length) return '2';
      if (!componentErrors.length) return '1';
      if (!componentErrors.length && !importErrors.length) return '3';

      return '1';
    };
    const items: TabsProps['items'] = [
      {
        key: '1',
        label: 'Line Errors',
        disabled: !importErrors.length,
        children: (
          <div style={{ border: '1px solid rgba(0,0,0,.1)', borderRadius: 10, padding: 10, marginTop: 20, overflow: 'scroll', height: 600 }}>
            <Row style={{ marginTop: 20, marginLeft: 5, marginBottom: 5 }}>
              <Col span={5}>
                <Typography.Text style={{ fontSize: 15 }}>Kit Id</Typography.Text>
              </Col>
              <Col span={19}>
                <Typography.Text style={{ fontSize: 15 }}>Message</Typography.Text>
              </Col>
            </Row>
            {importErrors.map((error, i) => (
              <Row gutter={[10, 10]} key={error.errorMessage + i} style={{ padding: 10, background: 'rgba(0,0,0,.1)', borderRadius: 10, marginBottom: 10 }}>
                <Col span={5}>{error.kitD}</Col>
                <Col span={19}>{error.errorMessage}</Col>
              </Row>
            ))}
          </div>
        )
      },
      {
        key: '2',
        label: 'Component Errors',
        disabled: !componentErrors.length,
        children: (
          <div style={{ border: '1px solid rgba(0,0,0,.1)', borderRadius: 10, padding: 10, marginTop: 20, overflow: 'scroll', height: 600 }}>
            <Row style={{ marginTop: 20, marginLeft: 5, marginBottom: 5 }}>
              <Col span={3}>
                <Typography.Text style={{ fontSize: 15 }}>Kit Id</Typography.Text>
              </Col>
              <Col span={3}>
                <Typography.Text style={{ fontSize: 15 }}>Item Id</Typography.Text>
              </Col>
              <Col span={3}>
                <Typography.Text style={{ fontSize: 15 }}>Line Type</Typography.Text>
              </Col>
              <Col span={15}>
                <Typography.Text style={{ fontSize: 15 }}>Message</Typography.Text>
              </Col>
            </Row>
            {componentErrors.map((error, i) => (
              <Row gutter={[10, 10]} key={error.errorMessage + i} style={{ padding: 10, background: 'rgba(0,0,0,.1)', borderRadius: 10, marginBottom: 10 }}>
                <Col span={3}>{error.kitD}</Col>
                <Col span={3}>{error.itemId}</Col>
                <Col span={3}>{error.lineType}</Col>
                <Col span={15}>{error.errorMessage}</Col>
              </Row>
            ))}
          </div>
        )
      },
      {
        key: '3',
        label: 'SubComponent Errors',
        disabled: !subComponentErrors.length,
        children: (
          <div style={{ border: '1px solid rgba(0,0,0,.1)', borderRadius: 10, padding: 10, marginTop: 20, overflow: 'scroll', height: 600 }}>
            <Row style={{ marginTop: 20, marginLeft: 5, marginBottom: 5 }}>
              <Col span={3}>
                <Typography.Text style={{ fontSize: 15 }}>Kit Id</Typography.Text>
              </Col>
              <Col span={3}>
                <Typography.Text style={{ fontSize: 15 }}>Parent Item Id</Typography.Text>
              </Col>
              <Col span={3}>
                <Typography.Text style={{ fontSize: 15 }}>Item Id</Typography.Text>
              </Col>
              <Col span={3}>
                <Typography.Text style={{ fontSize: 15 }}>Line Type</Typography.Text>
              </Col>
              <Col span={12}>
                <Typography.Text style={{ fontSize: 15 }}>Message</Typography.Text>
              </Col>
            </Row>
            {subComponentErrors.map((error, i) => (
              <Row gutter={[10, 10]} key={error.errorMessage + i} style={{ padding: 10, background: 'rgba(0,0,0,.1)', borderRadius: 10, marginBottom: 10 }}>
                <Col span={3}>{error.kitD}</Col>
                <Col span={3}>{error.parentItemId}</Col>
                <Col span={3}>{error.itemId}</Col>
                <Col span={3}>{error.lineType}</Col>
                <Col span={12}>{error.errorMessage}</Col>
              </Row>
            ))}
          </div>
        )
      }
    ];

    Modal.confirm({
      title: 'Import Errors',
      bodyStyle: { maxHeight: 800 },
      cancelText: 'Export',
      onCancel: () => {
        const worksheeet = utils.json_to_sheet(importErrors);
        const componentSheet = utils.json_to_sheet(componentErrors);
        const subSheet = utils.json_to_sheet(subComponentErrors);

        const workbook = utils.book_new();

        utils.book_append_sheet(workbook, worksheeet, 'Line Errors');
        utils.book_append_sheet(workbook, componentSheet, 'Component Errors');
        utils.book_append_sheet(workbook, subSheet, 'Sub Component Errors');
        writeFile(workbook, 'non-assembled-kits-import-errors.xlsx');
      },
      width: 1000,
      icon: <></>,
      content: <Tabs defaultActiveKey={findTab()} items={items} />
    });
  };

  const handleImport = async (importData: KitPayload[]): Promise<void> => {
    const res = await triggerImport(importData).unwrap();

    (!!res.lineErrors.length || !!res.componentErrors.length || !!res.subComponentErrors) && handleErrorModal(res.lineErrors, res.componentErrors, res.subComponentErrors);
  };

  const parseData = async (file: RcFile): Promise<void> => {
    const reader = new FileReader();

    reader.onload = (e): void => {
      if (e.target && e.target.result !== null) {
        const data = e.target.result;
        const workbook = read(data, { type: 'array' });

        const sheetNameAssetHeader = workbook.SheetNames[0];

        const worksheetAssetHeader = workbook.Sheets[sheetNameAssetHeader];

        const jsonAssetHeader = utils.sheet_to_json(worksheetAssetHeader, { raw: false });

        // groups the data by kitId and their respective components/subcomponents

        const payload = jsonAssetHeader.reduce((acc: any, item: any) => {
          let kit = acc.find((k: any) => k.kitId === item.KitId);

          if (!kit) {
            kit = {
              kitId: item.KitId,
              description: item.KitDescription,
              divisionId,
              isActive: true,
              components: []
            };
            acc.push(kit);
          }

          if (item.LineType === 'Main') {
            kit.components.push({
              itemId: item.MainComponentProductNumber,
              lineType: item.LineType,
              componentQuantity: item.Quantity,
              manufacturer: '',
              category: '',
              description: '',
              subComponents: []
            });
          } else if (item.LineType === 'SubComponent') {
            const mainComponent = kit.components.find((component: any) => component.itemId === item.MainComponentProductNumber);

            if (mainComponent) {
              mainComponent.subComponents.push({
                lineType: item.LineType,
                itemId: item.SubComponentProductNumber,
                componentQuantity: item.Quantity,
                manufacturer: '',
                category: '',
                description: ''
              });
            }
          }

          return acc;
        }, []) as KitPayload[];

        handleImport(payload);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <Row align={'middle'}>
      <Tooltip title="Download import template">
        <Button style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} type="primary" download href={`/MDSi_Non_Assembled_Kit_Import_Template.xlsx`}>
          <DownloadOutlined />
        </Button>
      </Tooltip>
      <Upload beforeUpload={parseData} showUploadList={false}>
        <Button style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeft: '1px solid white' }} type="primary" disabled={false} loading={false}>
          Import
        </Button>
      </Upload>
    </Row>
  );
};
