import * as Yup from 'yup';
import { Product } from './Product';

interface Business {
  dataAreaId: string;
  erpId: string;
  id: string;
  name: string;
}

interface DivisionMetadata {
  business: Business;
  erpId: string;
  id: string;
  name: string;
}

export interface Component {
  alternateItemId?: string;
  category: string;
  componentQuantity: number;
  description: string | null;
  itemId: string | null;
  lineType: string;
  manufacturer: string;
  subComponents: Component[];
}

export interface KitResponse {
  id: string;
  kitId: string;
  description: string;
  divisionMetadata: DivisionMetadata;
  isActive: boolean;
  partitionKey: string;
  partitionKeyDescription: string;
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  components: Component[];
}

export type TableProduct = Partial<Product> & {
  lineType: string;
  componentQuantity: number;
};

export type KitPayload = {
  kitId: string;
  description: string;
  isActive: boolean;
  divisionId: string;
  components: Component[];
};

export const KitValidationSchema = Yup.object().shape({
  kitId: Yup.string().required('Kit Id is required'),
  description: Yup.string().required('Kit Description is required'),
  divisionId: Yup.string().required('Customer is required')
});
