import { Card, CardProps, Typography } from 'antd';
import { FC } from 'react';

const { Title } = Typography;

type Props = CardProps & {
  children: React.ReactNode;
  title: string | React.ReactNode;
  color: string;
  extra?: React.ReactNode;
};

export const ColorCard: FC<Props> = ({ children, title, color, ...rest }) => {
  return (
    <Card
      styles={{ header: { backgroundColor: color } }}
      title={
        typeof title === 'string' ? (
          <Title level={4} style={{ fontWeight: 'normal', margin: 0, padding: 0 }}>
            {title}
          </Title>
        ) : (
          title
        )
      }
      style={{ height: '100%', width: '100%' }}
      {...rest}>
      {children}
    </Card>
  );
};
