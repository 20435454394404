import { Col, Space } from 'antd';
import { useAuthentication } from 'hooks/useAuthentication';
import { FieldInput } from 'pages/Create/FormItems/FieldInput';
import { FieldInputSwitch } from 'pages/Create/FormItems/FieldInputSwitch';
import { useAppSelector } from 'redux/store';

export const KitForm = (): JSX.Element => {
  const { user } = useAppSelector((state) => state.app);

  // const isAdmin = user && user['https://acuity.mdsiinc.com/user/user_metadata'].is_mdsi_employee;
  const { isAdmin } = useAuthentication();

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Col>
        <FieldInput disabled={!isAdmin} fieldName="kitId" label="Kit Id" />
      </Col>
      <Col>
        <FieldInput disabled={!isAdmin} fieldName="description" label="Kit Description" />
      </Col>
      <Col>
        <FieldInputSwitch disabled={!isAdmin} fieldName="isActive" label="Is Active" />
      </Col>
    </Space>
  );
};
