/* eslint-disable jsx-a11y/label-has-associated-control */
import { FancySelect } from 'components/ui/Inputs';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { sparingRequestApi } from 'redux/services/twittlebugs';

export const StatusFilter = (): JSX.Element => {
  const [inputValue, _setInputValue] = useState<string[]>(['Open']);

  const dispatch = useDispatch();

  const options = ['Draft', 'Open', 'Open W/ Errors', 'Cancelled', 'Closed'].map((status) => ({ label: status, value: status }));

  /* ****************** Functions ****************** */
  const handleStatusChange = (value: string[]): void => {
    _setInputValue(value);

    dispatch(sparingRequestApi.util.invalidateTags(['SparingRequests']));
  };

  return <FancySelect mode="multiple" onChange={handleStatusChange} options={options} value={inputValue} placeholder="Status" />;
};
