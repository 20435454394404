import { DefaultOptionType } from 'antd/es/select';
import { FancySelect } from 'components/ui/Inputs';
import { State } from 'country-state-city';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setSubdivisionLocalCodeEquals } from 'redux/services/chuckieSue/addressesParams';
import { setLocality } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';

export const StateFilter: React.FC = () => {
  const { country, locality } = useAppSelector((state) => state.app);

  const dispatch = useDispatch();

  const states = country ? State.getStatesOfCountry(country) : [];

  const stateOptions: DefaultOptionType[] | undefined = states.map((state) => ({ label: state.name, value: state.isoCode }));

  const handleCountryChange = (value: string): void => {
    dispatch(setLocality(value));
    dispatch(setSubdivisionLocalCodeEquals(undefined));
  };

  return <FancySelect disabled={!country} placeholder="State" style={{ margin: 0 }} value={locality} onChange={handleCountryChange} showSearch options={stateOptions} />;
};
