import { Col, Row, Space } from 'antd';
import { FancyInput } from 'components/ui/Inputs';
import { OrderNameFilter, OrderNumberFilter, ReferenceFilter, RequesterFilter, StatusFilter } from './components';
import { CountryFilter } from './components/CountryFilter';
import { FancyDateRangePicker } from './components/FancyDateRangePicker';
import { StateFilter } from './components/StateFilter';

export const ControlFilters = (): JSX.Element => {
  return (
    <Space style={{ width: '100%' }} size={18} direction="vertical">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={8} lg={5} xl={5} xxl={5}>
          <OrderNameFilter />
        </Col>
        <Col xs={24} sm={24} md={8} lg={5} xl={5} xxl={5}>
          <ReferenceFilter />
        </Col>
        <Col xs={24} sm={24} md={8} lg={5} xl={5} xxl={5}>
          <RequesterFilter />
        </Col>
        <Col xs={24} sm={24} md={8} lg={5} xl={5} xxl={5}>
          <OrderNumberFilter />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={8} lg={5} xl={5} xxl={5}>
          <CountryFilter />
        </Col>
        <Col xs={24} sm={24} md={8} lg={5} xl={5} xxl={5}>
          <StateFilter />
        </Col>
        <Col xs={24} sm={24} md={8} lg={5} xl={5} xxl={5}>
          <FancyInput placeholder="City" />
        </Col>
        <Col xs={24} sm={24} md={8} lg={5} xl={5} xxl={5}>
          <StatusFilter />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={8} lg={5} xl={5} xxl={5}>
          <FancyDateRangePicker rangeValues={[]} label="Created Date Range" />
        </Col>
      </Row>
    </Space>
  );
};
